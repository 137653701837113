exports.components = {
  "component---src-pages-blog-clanok-botanika-js": () => import("./../../../src/pages/blog-clanok-botanika.js" /* webpackChunkName: "component---src-pages-blog-clanok-botanika-js" */),
  "component---src-pages-blog-clanok-historia-js": () => import("./../../../src/pages/blog-clanok-historia.js" /* webpackChunkName: "component---src-pages-blog-clanok-historia-js" */),
  "component---src-pages-blog-clanok-pivovarnictvo-js": () => import("./../../../src/pages/blog-clanok-pivovarnictvo.js" /* webpackChunkName: "component---src-pages-blog-clanok-pivovarnictvo-js" */),
  "component---src-pages-blog-clanok-pivovarnictvo-kopie-kq-4-db-7-xssx-7-js": () => import("./../../../src/pages/blog-clanok-pivovarnictvo-Kopie-kq4db7xssx7.js" /* webpackChunkName: "component---src-pages-blog-clanok-pivovarnictvo-kopie-kq-4-db-7-xssx-7-js" */),
  "component---src-pages-edicny-titul-js": () => import("./../../../src/pages/edicny-titul.js" /* webpackChunkName: "component---src-pages-edicny-titul-js" */),
  "component---src-pages-historia-muzea-js": () => import("./../../../src/pages/historia-muzea.js" /* webpackChunkName: "component---src-pages-historia-muzea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacna-povinnost-js": () => import("./../../../src/pages/informacna-povinnost.js" /* webpackChunkName: "component---src-pages-informacna-povinnost-js" */),
  "component---src-pages-kniznica-js": () => import("./../../../src/pages/kniznica.js" /* webpackChunkName: "component---src-pages-kniznica-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mozaika-remesiel-js": () => import("./../../../src/pages/mozaika-remesiel.js" /* webpackChunkName: "component---src-pages-mozaika-remesiel-js" */),
  "component---src-pages-muzeum-online-js": () => import("./../../../src/pages/muzeum-online.js" /* webpackChunkName: "component---src-pages-muzeum-online-js" */),
  "component---src-pages-najstarsie-tituly-js": () => import("./../../../src/pages/najstarsie-tituly.js" /* webpackChunkName: "component---src-pages-najstarsie-tituly-js" */),
  "component---src-pages-ochrana-osobnych-udajov-js": () => import("./../../../src/pages/ochrana-osobnych-udajov.js" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-js" */),
  "component---src-pages-podujatia-js": () => import("./../../../src/pages/podujatia.js" /* webpackChunkName: "component---src-pages-podujatia-js" */),
  "component---src-pages-polovnickym-chodnickom-js": () => import("./../../../src/pages/polovnickym-chodnickom.js" /* webpackChunkName: "component---src-pages-polovnickym-chodnickom-js" */),
  "component---src-pages-polstorocnica-muzea-js": () => import("./../../../src/pages/polstorocnica-muzea.js" /* webpackChunkName: "component---src-pages-polstorocnica-muzea-js" */),
  "component---src-pages-ponuka-tlacovin-js": () => import("./../../../src/pages/ponuka-tlacovin.js" /* webpackChunkName: "component---src-pages-ponuka-tlacovin-js" */),
  "component---src-pages-predmet-mesiaca-js": () => import("./../../../src/pages/predmet-mesiaca.js" /* webpackChunkName: "component---src-pages-predmet-mesiaca-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-sekciu-pripravujeme-js": () => import("./../../../src/pages/sekciu-pripravujeme.js" /* webpackChunkName: "component---src-pages-sekciu-pripravujeme-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */),
  "component---src-pages-voda-zaklad-zivota-js": () => import("./../../../src/pages/voda-zaklad-zivota.js" /* webpackChunkName: "component---src-pages-voda-zaklad-zivota-js" */),
  "component---src-pages-vyberove-konania-na-pracovne-miesta-js": () => import("./../../../src/pages/vyberove-konania-na-pracovne-miesta.js" /* webpackChunkName: "component---src-pages-vyberove-konania-na-pracovne-miesta-js" */),
  "component---src-pages-zverejnovanie-js": () => import("./../../../src/pages/zverejnovanie.js" /* webpackChunkName: "component---src-pages-zverejnovanie-js" */)
}

